@font-face {
    font-family: 'HSBombaram';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/HSBombaram.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GmarketSansTTFLight';
    src: url('../public/assets/font/GmarketSansTTFLight.ttf')
}

.Font_gmarket {
    font-family: 'GmarketSansTTFLight';
}

.Font_bombaram {
    font-family: 'HSBombaram';
}

@media (min-width: 375px) {
    .App,
    .ShowNav {
        width: 375px;
    }
}

@media (max-width:500px) {
    .App,
    .ShowNav {
        width: 100vw;
    }
}

#root {
    box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.App {
    min-height: 100vh;
    background-image: url('../public/assets/img/mainImg.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    color: black;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF_HailSnow';
    min-height: 100vh;
    margin: 0;
}

.verticalLeft {
    writing-mode: vertical-rl;
    color: #DEDEDE;
}

.verticalRight {
    writing-mode: vertical-rl;
    transform: scaleX(-1);
    transform: rotate(180deg);
    color: #DEDEDE;
}

.bottomText {
    letter-spacing: 15px;
}

.Maintitle {
    writing-mode: vertical-rl;
}

.TItleIMG {
    background-image: url('../public/assets/img/Pagetitle.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
}

.TItleIMG2 {
    background-image: url('../public/assets/img/TitleLine.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.TitleLineShort {
    background-image: url('../public/assets/img/TitleLineShort.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Subtitle {
    color: #d94925;
    font-size: 30px;
    margin: 10px auto;
}

.last:last-child {
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.last30:last-child {
    min-height: 45vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.bottomText {
    letter-spacing: 15px;
    padding: 3px;
}

.mainbtn {
    background-color: #a8a8a8;
    color: white;
}


/* 입후보자 정보 */

.CandidateBtn {
    margin: 8px;
    background-color: #eee;
    width: 60%;
    font-size: 23px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
}

.CandidateBtn:last-child {
    margin-bottom: 40px;
}

.CandidateBtn0 {
    margin: 15px;
    background-color: #eee;
    width: 60%;
    font-size: 23px;
    padding: 7px;
    border-radius: 10px;
    box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
}


/* 배치도 */

.pointColor {
    color: #d94925;
}

.focus-in-expand {
    -webkit-animation: focus-in-expand 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: focus-in-expand 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
* Generated by Animista on 2023-11-5 16:46:20
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */


/**
* ----------------------------------------
* animation focus-in-expand
* ----------------------------------------
*/

@-webkit-keyframes focus-in-expand {
    0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes focus-in-expand {
    0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.fade-in-top {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
* Generated by Animista on 2023-11-5 18:10:14
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */


/**
* ----------------------------------------
* animation fade-in-top
* ----------------------------------------
*/

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.Maintitle {
    background-image: url('../public/assets/img/nav/verti.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat-y;
    height: 250px;
    text-align: center;
}