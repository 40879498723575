.ShowNav {
    min-height: 100vh;
    background-image: url('../../public/assets/img/mainImg.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    color: black;
}

.NavTitle {
    writing-mode: vertical-rl;
    margin-top: 0px;
}

.NavBtn {
    font-size: 28px;
    background-color: #EEE;
    width: 220px;
    padding: 8px 15px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 11px 10px 7px -4px rgba(0, 0, 0, 0.13);
}


/* Nav */

.menugreen {
    background-color: black;
}

.nav-bg-scrolled {
    background-color: rgb(251, 244, 232, 0.1);
    backdrop-filter: blur(4px);
}

.hamburger-menu {
    height: 40px;
    width: 35px;
    position: relative;
    padding: 1rem;
}

.ham-bar {
    width: 70%;
    height: 2px;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform .6s, opacity .8s, top .6s;
}

.bar-top {
    top: 25%;
}

.bar-bottom {
    top: 75%
}

.hamburger-menu.active .bar-top {
    transform: translate(-50%, -50%) rotate(-315deg);
    top: 50%
}

.hamburger-menu.active .bar-mid {
    opacity: 0;
}

.hamburger-menu.active .bar-bottom {
    transform: translate(-50%, -50%) rotate(-225deg);
    top: 50%;
}

.off-screen-menu {
    top: 0;
    left: 0;
    background-color: white;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    transition: opacity .6s ease-in-out;
    z-index: 30;
}

.off-screen-menu.open {
    opacity: 1;
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.NavTitle {
    background-image: url('../../public/assets/img/nav/verti.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat-y;
    height: 180px;
    text-align: center;
}

.navHori {
    background-image: url('../../public/assets/img/nav/hori.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat-y;
    width: 180px;
    text-align: center;
}